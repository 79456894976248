import { default as aboutWEaj4FSKkQMeta } from "/vercel/path0/pages/app/[event]/about.vue?macro=true";
import { default as agendaJWgTwNkKZGMeta } from "/vercel/path0/pages/app/[event]/agenda.vue?macro=true";
import { default as feedbackLukhZSqm7jMeta } from "/vercel/path0/pages/app/[event]/feedback.vue?macro=true";
import { default as helpdeskwS7NHHuEE4Meta } from "/vercel/path0/pages/app/[event]/helpdesk.vue?macro=true";
import { default as indexaPQA7yLUNHMeta } from "/vercel/path0/pages/app/[event]/index.vue?macro=true";
import { default as moreg6yCE9lJ0CMeta } from "/vercel/path0/pages/app/[event]/more.vue?macro=true";
import { default as _91_id_93lhCEK3el8UMeta } from "/vercel/path0/pages/app/[event]/news/[_id].vue?macro=true";
import { default as indexUkQXsSPh6qMeta } from "/vercel/path0/pages/app/[event]/news/index.vue?macro=true";
import { default as _91_id_93JaAFOFiXRzMeta } from "/vercel/path0/pages/app/[event]/speakers/[_id].vue?macro=true";
import { default as indexEgBOgGP6JwMeta } from "/vercel/path0/pages/app/[event]/speakers/index.vue?macro=true";
import { default as _91_id_93GDF43r7BLBMeta } from "/vercel/path0/pages/app/[event]/sponsors/[_id].vue?macro=true";
import { default as indexmdgCJ9lMN9Meta } from "/vercel/path0/pages/app/[event]/sponsors/index.vue?macro=true";
import { default as _91event_93teXoCNLwrqMeta } from "/vercel/path0/pages/app/[event].vue?macro=true";
import { default as access6RYM4CjGmkMeta } from "/vercel/path0/pages/app/access.vue?macro=true";
import { default as ui_45kitoPli0xSOR4Meta } from "/vercel/path0/pages/app/ui-kit.vue?macro=true";
import { default as _91event_93pZaeY7Dug5Meta } from "/vercel/path0/pages/event/[event].vue?macro=true";
import { default as analyticsLWyQDFGXRtMeta } from "/vercel/path0/pages/events/[_id]/analytics.vue?macro=true";
import { default as _91_template_93aLJlm07KP8Meta } from "/vercel/path0/pages/events/[_id]/builder/[_template].vue?macro=true";
import { default as agendak8e0daXbFnMeta } from "/vercel/path0/pages/events/[_id]/guest-app/agenda.vue?macro=true";
import { default as feedbackP3SXDJ4EdJMeta } from "/vercel/path0/pages/events/[_id]/guest-app/feedback.vue?macro=true";
import { default as helpdeskUo34esg8pJMeta } from "/vercel/path0/pages/events/[_id]/guest-app/helpdesk.vue?macro=true";
import { default as newsbL7gYhTq3oMeta } from "/vercel/path0/pages/events/[_id]/guest-app/news.vue?macro=true";
import { default as other5ZfGtLQXjbMeta } from "/vercel/path0/pages/events/[_id]/guest-app/other.vue?macro=true";
import { default as ratingwRRLxRZ4ITMeta } from "/vercel/path0/pages/events/[_id]/guest-app/rating.vue?macro=true";
import { default as speakersDzG0PBLPmBMeta } from "/vercel/path0/pages/events/[_id]/guest-app/speakers.vue?macro=true";
import { default as sponsorshlmZLBvNyXMeta } from "/vercel/path0/pages/events/[_id]/guest-app/sponsors.vue?macro=true";
import { default as guestsCdrvh0IdSBMeta } from "/vercel/path0/pages/events/[_id]/guests.vue?macro=true";
import { default as indexDtEARTFeoIMeta } from "/vercel/path0/pages/events/[_id]/index.vue?macro=true";
import { default as mailing6Z3uWYADguMeta } from "/vercel/path0/pages/events/[_id]/mailing.vue?macro=true";
import { default as check_45inOjiolDPsoFMeta } from "/vercel/path0/pages/events/[_id]/settings/check-in.vue?macro=true";
import { default as collaboratorsENgjUwAwFaMeta } from "/vercel/path0/pages/events/[_id]/settings/collaborators.vue?macro=true";
import { default as generalsyOYavIkrbMeta } from "/vercel/path0/pages/events/[_id]/settings/general.vue?macro=true";
import { default as settingsaLdYgsI4a5Meta } from "/vercel/path0/pages/events/[_id]/settings.vue?macro=true";
import { default as emailLTvknuNsKLMeta } from "/vercel/path0/pages/events/[_id]/templates/email.vue?macro=true";
import { default as index9H757a9gePMeta } from "/vercel/path0/pages/events/[_id]/templates/index.vue?macro=true";
import { default as pagewa6WkXKKicMeta } from "/vercel/path0/pages/events/[_id]/templates/page.vue?macro=true";
import { default as _91_id_93ZJuJTvNfW6Meta } from "/vercel/path0/pages/events/[_id].vue?macro=true";
import { default as indexuedj0fLb5OMeta } from "/vercel/path0/pages/events/index.vue?macro=true";
import { default as forgotten_45password6vATTz81FwMeta } from "/vercel/path0/pages/forgotten-password.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as logint0AWlhQgM0Meta } from "/vercel/path0/pages/login.vue?macro=true";
import { default as new_45passwordtlTx6shQZhMeta } from "/vercel/path0/pages/new-password.vue?macro=true";
import { default as _91quote_93keoPUOe1c2Meta } from "/vercel/path0/pages/quote/[quote].vue?macro=true";
import { default as registerIikvtlfpbHMeta } from "/vercel/path0/pages/register.vue?macro=true";
import { default as collaboratorsf55dU9IJ3fMeta } from "/vercel/path0/pages/setup/collaborators.vue?macro=true";
import { default as indexjD90zBEa5SMeta } from "/vercel/path0/pages/setup/index.vue?macro=true";
import { default as setupdIoIVoy25vMeta } from "/vercel/path0/pages/setup.vue?macro=true";
import { default as ui_45kitU8yWMviKQ4Meta } from "/vercel/path0/pages/ui-kit.vue?macro=true";
import { default as welcomelxppa1y2l8Meta } from "/vercel/path0/pages/welcome.vue?macro=true";
export default [
  {
    name: _91event_93teXoCNLwrqMeta?.name,
    path: "/app/:event()",
    meta: _91event_93teXoCNLwrqMeta || {},
    component: () => import("/vercel/path0/pages/app/[event].vue"),
    children: [
  {
    name: "app-event-about",
    path: "about",
    meta: aboutWEaj4FSKkQMeta || {},
    component: () => import("/vercel/path0/pages/app/[event]/about.vue")
  },
  {
    name: "app-event-agenda",
    path: "agenda",
    meta: agendaJWgTwNkKZGMeta || {},
    component: () => import("/vercel/path0/pages/app/[event]/agenda.vue")
  },
  {
    name: "app-event-feedback",
    path: "feedback",
    meta: feedbackLukhZSqm7jMeta || {},
    component: () => import("/vercel/path0/pages/app/[event]/feedback.vue")
  },
  {
    name: "app-event-helpdesk",
    path: "helpdesk",
    meta: helpdeskwS7NHHuEE4Meta || {},
    component: () => import("/vercel/path0/pages/app/[event]/helpdesk.vue")
  },
  {
    name: "app-event",
    path: "",
    meta: indexaPQA7yLUNHMeta || {},
    component: () => import("/vercel/path0/pages/app/[event]/index.vue")
  },
  {
    name: "app-event-more",
    path: "more",
    meta: moreg6yCE9lJ0CMeta || {},
    component: () => import("/vercel/path0/pages/app/[event]/more.vue")
  },
  {
    name: "app-event-news-_id",
    path: "news/:_id()",
    component: () => import("/vercel/path0/pages/app/[event]/news/[_id].vue")
  },
  {
    name: "app-event-news",
    path: "news",
    meta: indexUkQXsSPh6qMeta || {},
    component: () => import("/vercel/path0/pages/app/[event]/news/index.vue")
  },
  {
    name: "app-event-speakers-_id",
    path: "speakers/:_id()",
    component: () => import("/vercel/path0/pages/app/[event]/speakers/[_id].vue")
  },
  {
    name: "app-event-speakers",
    path: "speakers",
    meta: indexEgBOgGP6JwMeta || {},
    component: () => import("/vercel/path0/pages/app/[event]/speakers/index.vue")
  },
  {
    name: "app-event-sponsors-_id",
    path: "sponsors/:_id()",
    component: () => import("/vercel/path0/pages/app/[event]/sponsors/[_id].vue")
  },
  {
    name: "app-event-sponsors",
    path: "sponsors",
    meta: indexmdgCJ9lMN9Meta || {},
    component: () => import("/vercel/path0/pages/app/[event]/sponsors/index.vue")
  }
]
  },
  {
    name: "app-access",
    path: "/app/access",
    meta: access6RYM4CjGmkMeta || {},
    component: () => import("/vercel/path0/pages/app/access.vue")
  },
  {
    name: "app-ui-kit",
    path: "/app/ui-kit",
    meta: ui_45kitoPli0xSOR4Meta || {},
    component: () => import("/vercel/path0/pages/app/ui-kit.vue")
  },
  {
    name: "event-event",
    path: "/event/:event()",
    meta: _91event_93pZaeY7Dug5Meta || {},
    component: () => import("/vercel/path0/pages/event/[event].vue")
  },
  {
    name: _91_id_93ZJuJTvNfW6Meta?.name,
    path: "/events/:_id()",
    meta: _91_id_93ZJuJTvNfW6Meta || {},
    component: () => import("/vercel/path0/pages/events/[_id].vue"),
    children: [
  {
    name: "events-_id-analytics",
    path: "analytics",
    meta: analyticsLWyQDFGXRtMeta || {},
    component: () => import("/vercel/path0/pages/events/[_id]/analytics.vue")
  },
  {
    name: "events-_id-builder-_template",
    path: "builder/:_template()",
    meta: _91_template_93aLJlm07KP8Meta || {},
    component: () => import("/vercel/path0/pages/events/[_id]/builder/[_template].vue")
  },
  {
    name: "events-_id-guest-app-agenda",
    path: "guest-app/agenda",
    meta: agendak8e0daXbFnMeta || {},
    component: () => import("/vercel/path0/pages/events/[_id]/guest-app/agenda.vue")
  },
  {
    name: "events-_id-guest-app-feedback",
    path: "guest-app/feedback",
    meta: feedbackP3SXDJ4EdJMeta || {},
    component: () => import("/vercel/path0/pages/events/[_id]/guest-app/feedback.vue")
  },
  {
    name: "events-_id-guest-app-helpdesk",
    path: "guest-app/helpdesk",
    meta: helpdeskUo34esg8pJMeta || {},
    component: () => import("/vercel/path0/pages/events/[_id]/guest-app/helpdesk.vue")
  },
  {
    name: "events-_id-guest-app-news",
    path: "guest-app/news",
    meta: newsbL7gYhTq3oMeta || {},
    component: () => import("/vercel/path0/pages/events/[_id]/guest-app/news.vue")
  },
  {
    name: "events-_id-guest-app-other",
    path: "guest-app/other",
    meta: other5ZfGtLQXjbMeta || {},
    component: () => import("/vercel/path0/pages/events/[_id]/guest-app/other.vue")
  },
  {
    name: "events-_id-guest-app-rating",
    path: "guest-app/rating",
    meta: ratingwRRLxRZ4ITMeta || {},
    component: () => import("/vercel/path0/pages/events/[_id]/guest-app/rating.vue")
  },
  {
    name: "events-_id-guest-app-speakers",
    path: "guest-app/speakers",
    meta: speakersDzG0PBLPmBMeta || {},
    component: () => import("/vercel/path0/pages/events/[_id]/guest-app/speakers.vue")
  },
  {
    name: "events-_id-guest-app-sponsors",
    path: "guest-app/sponsors",
    meta: sponsorshlmZLBvNyXMeta || {},
    component: () => import("/vercel/path0/pages/events/[_id]/guest-app/sponsors.vue")
  },
  {
    name: "events-_id-guests",
    path: "guests",
    meta: guestsCdrvh0IdSBMeta || {},
    component: () => import("/vercel/path0/pages/events/[_id]/guests.vue")
  },
  {
    name: "events-_id",
    path: "",
    component: () => import("/vercel/path0/pages/events/[_id]/index.vue")
  },
  {
    name: "events-_id-mailing",
    path: "mailing",
    meta: mailing6Z3uWYADguMeta || {},
    component: () => import("/vercel/path0/pages/events/[_id]/mailing.vue")
  },
  {
    name: "events-_id-settings",
    path: "settings",
    meta: settingsaLdYgsI4a5Meta || {},
    component: () => import("/vercel/path0/pages/events/[_id]/settings.vue"),
    children: [
  {
    name: "events-_id-settings-check-in",
    path: "check-in",
    component: () => import("/vercel/path0/pages/events/[_id]/settings/check-in.vue")
  },
  {
    name: "events-_id-settings-collaborators",
    path: "collaborators",
    component: () => import("/vercel/path0/pages/events/[_id]/settings/collaborators.vue")
  },
  {
    name: "events-_id-settings-general",
    path: "general",
    component: () => import("/vercel/path0/pages/events/[_id]/settings/general.vue")
  }
]
  },
  {
    name: "events-_id-templates-email",
    path: "templates/email",
    meta: emailLTvknuNsKLMeta || {},
    component: () => import("/vercel/path0/pages/events/[_id]/templates/email.vue")
  },
  {
    name: "events-_id-templates",
    path: "templates",
    component: () => import("/vercel/path0/pages/events/[_id]/templates/index.vue")
  },
  {
    name: "events-_id-templates-page",
    path: "templates/page",
    meta: pagewa6WkXKKicMeta || {},
    component: () => import("/vercel/path0/pages/events/[_id]/templates/page.vue")
  }
]
  },
  {
    name: "events",
    path: "/events",
    meta: indexuedj0fLb5OMeta || {},
    component: () => import("/vercel/path0/pages/events/index.vue")
  },
  {
    name: "forgotten-password",
    path: "/forgotten-password",
    meta: forgotten_45password6vATTz81FwMeta || {},
    component: () => import("/vercel/path0/pages/forgotten-password.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: logint0AWlhQgM0Meta || {},
    component: () => import("/vercel/path0/pages/login.vue")
  },
  {
    name: "new-password",
    path: "/new-password",
    meta: new_45passwordtlTx6shQZhMeta || {},
    component: () => import("/vercel/path0/pages/new-password.vue")
  },
  {
    name: "quote-quote",
    path: "/quote/:quote()",
    meta: _91quote_93keoPUOe1c2Meta || {},
    component: () => import("/vercel/path0/pages/quote/[quote].vue")
  },
  {
    name: "register",
    path: "/register",
    meta: registerIikvtlfpbHMeta || {},
    component: () => import("/vercel/path0/pages/register.vue")
  },
  {
    name: setupdIoIVoy25vMeta?.name,
    path: "/setup",
    meta: setupdIoIVoy25vMeta || {},
    component: () => import("/vercel/path0/pages/setup.vue"),
    children: [
  {
    name: "setup-collaborators",
    path: "collaborators",
    meta: collaboratorsf55dU9IJ3fMeta || {},
    component: () => import("/vercel/path0/pages/setup/collaborators.vue")
  },
  {
    name: "setup",
    path: "",
    meta: indexjD90zBEa5SMeta || {},
    component: () => import("/vercel/path0/pages/setup/index.vue")
  }
]
  },
  {
    name: "ui-kit",
    path: "/ui-kit",
    meta: ui_45kitU8yWMviKQ4Meta || {},
    component: () => import("/vercel/path0/pages/ui-kit.vue")
  },
  {
    name: "welcome",
    path: "/welcome",
    meta: welcomelxppa1y2l8Meta || {},
    component: () => import("/vercel/path0/pages/welcome.vue")
  }
]