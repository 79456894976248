import animateScrollTo from 'animated-scroll-to';

const directive = (el, binding) => {
  el.addEventListener('click', (event) => {
    let element;

    if (typeof (binding.value) === 'string' && binding.value.replace('#', '').length) {
      element = document.querySelector(binding.value);

      if (element) {
        let headerHeight = 0;
        const headerElement = document.querySelector('header');

        if (headerElement) {
          headerHeight = headerElement.offsetHeight;
        }

        const verticalOffset = headerHeight;

        animateScrollTo(element, {
          elementToScroll: window,
          verticalOffset: -verticalOffset,
        });
      } else {
        console.error(`AnimateScroll - element (${binding.value}) doest exist`);
      }
    }

    event.preventDefault();
  });
};

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.directive('animated-scroll-to', directive);
});
