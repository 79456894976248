<template>
  <Html :lang="$i18n.locale">
    <Body id="app">
      <el-config-provider :locale="elLocale">
        <nuxt-layout v-if="useWorkspaceStore().domain">
          <nuxt-loading-indicator
            color="#000"
            :height="1"
          />
          <nuxt-page />
        </nuxt-layout>
        <div
          v-else
          v-loading="true"
          class="w-full h-[100dvh]"
        />
      </el-config-provider>
    </Body>
  </Html>
</template>

<script setup>
import en from 'element-plus/dist/locale/en.mjs';
import cs from 'element-plus/dist/locale/cs.mjs';

const { refresh } = useAsyncData('workspace', () => useWorkspaceStore().fetchWorkspace());

// Refs
const i18n = useI18n();
const isOfflineToastClose = ref(false);
const route = useRoute();

// Computed
const elLocale = computed(() => {
  if (i18n.locale.value === 'en') {
    return en;
  }
  cs.el.datepicker.year = '';
  return cs;
});

const isOnline = useOnline();

// Lifecycle
onMounted(async() => {
  if (!useWorkspaceStore().domain) {
    await refresh();
  }

  useNuxtApp()?.$sentry?.setTag?.('workspace', useWorkspaceStore().domain);
  useNuxtApp()?.$sentry?.setTag?.('browser.width', window.innerWidth);
  useNuxtApp()?.$sentry?.setTag?.('browser.height', window.innerHeight);

  if (!['page', 'guest-app'].includes(useRoute()?.meta?.layout)) {
    useNuxtApp()?.$replay?.start();
  }
});

onBeforeUnmount(() => {
  useNuxtApp()?.$replay?.stop();
});

watch(i18n.locale, () => {
  if (['auth', 'main'].includes(route.meta.layout)) {
    const isLocaleAvailableInInterface = i18n.locales.value.find(({ code }) => code === i18n.locale.value)?.inInterface;
    if (!isLocaleAvailableInInterface) {
      i18n.locale.value = 'en';
    }
  }

  useDayjs.locale(i18n.locale.value);
  useNuxtApp()?.$sentry?.setTag?.('locale', i18n.locale.value);
}, { immediate: true });

watch(() => useAuthStore().user, (user) => {
  if (user) {
    useNuxtApp()?.$sentry?.setUser({
      id: user?.id,
      email: user?.email,
      username: user?.fullName,
    });
  }
}, { immediate: true });

watch(isOnline, () => {
  if (!isOnline.value) {
    const { close } = useToast(useT('xyz107'), {
      duration: 0,
    });
    isOfflineToastClose.value = close;
  } else {
    isOfflineToastClose.value?.();
  }
});

if (useRequestURL().host.includes('.attendu.cz')) { // redirects from attendu2
  navigateTo(useRequestURL().origin.replace('.attendu.cz', '.attendu.com'), { external: true });
}
</script>
